import { useEffect, useRef } from "react";
import multiavatar from "@multiavatar/multiavatar/esm";

import "./index.css";

function Face(props) {
    const svgRef = useRef();
    // 首次加载
    useEffect(() => {
        loadCreateFace();
    }, [props]);
    // 加载默认头像
    const loadCreateFace = () => {
        if (props.address) {
            const faceCode = props.address.toString().toLocaleLowerCase();
            const svgCode = multiavatar(faceCode);
            svgRef.current.innerHTML = svgCode;
        }
    };

    return <div className={props.class} ref={svgRef}></div>;
}

export default Face;
