import React, { useState, useEffect } from "react";

import copy from "clipboard-copy";
import { FaBolt, FaCreativeCommonsSampling } from "react-icons/fa";

import TopNavigationBar from "../../components/TopNavigationBar"; // 导入顶部导航栏组件
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';
import FacebookShareButton from '../../components/FacebookShareButton';

import "./index.css";

import config from "../../utils/global";
import { noTokenGet } from "../../utils/http";
import { useGlobalState } from "../../store/index";
import { error, info } from "../../utils/toast";

const GameDetails = () => {

  const [globalUserId] = useGlobalState(
    "globalUserId"
  );
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [globalEnergy] = useGlobalState(
    "globalEnergy"
  );
  const searchParams = new URLSearchParams(window.location.search);
  const gid = searchParams.get("gid");
  const inviter = searchParams.get("inviter");

  const [details, setDetails] = useState({});
  const [shareParams, setShareParams] = useState({});
  const [gameHistory, setGameHistory] = useState([]);

  const [loaderState, setLoaderState] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetchGameDetail();
      if (inviter) {
        await fetchAddInviteRecord();
      }
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchGameDetail = async () => {
    const rep = await noTokenGet("g1Apps/getGameInfo", {
      gameId: gid,
    });
    if (rep.code === 0) {
      setDetails(rep.data.gameInfo);
      await fetchGameHistory();
    } else {
      error(rep.msg);
    }
  };

  const fetchGameHistory = async () => {
    const rep = await noTokenGet("g1Apps/getGameRecord", {
      gameId: gid,
    });
    if (rep.code === 0) {
      setGameHistory(rep.data.gameRecords);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const fetchAddInviteRecord = async () => {
    const rep = await noTokenGet("g1Apps/addInviteRecord", {
      gameId: gid,
      fromId: inviter,
    });
    if (rep.code === 0) {
      // 记录邀请
    } else {
      error(rep.msg);
    }
  };

  const handlePlay = (_gid, _need_energy, _game_url) => {
    if (globalEnergy >= _need_energy) {
      let localXtoken = window.localStorage.getItem("x-token") || '';
      let localYtoken = window.localStorage.getItem("y-token") || '';
      let play_url = `${_game_url}?gameId=${_gid}&x-token=${localXtoken}&y-token=${localYtoken}`;
      window.location.href = play_url;
    } else {
      info(`Your game lacks physical strength and can be won through a lottery.`);
    }
  };

  const openShareModal = (_name, _share, _img) => {
    let share = {
      gameName: _name,
      gameDesc: _share,
      gameImg: _img
    };
    setShareParams(share);
    copy(_share);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="app">
      <Seo
        title={`1Game - `}
        description={`1Game - `}
        keywords={`1Game,`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/1game.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} back={true} />
      {details && (
        <div className="game-details">
          <div className="pic">
            <ImageLoader
              src={details.game_cover}
              alt={details.game_name}
            />
          </div>
          <div className="li">
            {details.game_name}
          </div>
          {globalIsLogin === true && (
            <div className='facebook-share-button' onClick={() => openShareModal(details.game_name, `I’m having a blast with this game (${details.game_name}), come play with me! Let’s earn rewards together! ${config.api.siteURL}/gameDetails?gid=${details.id}&inviter=${globalUserId} #1Game #FunFusion #${details.game_name}`, details.game_cover)}>
              Share it on Facebook
            </div>
          )}
          <div className="cmd">
            {globalEnergy >= details.need_energy ? (
              <Button
                size={"small-default"}
                onClick={() => handlePlay(details.id, details.need_energy, details.game_url)}>
                Play&nbsp;/&nbsp;<FaBolt />&nbsp;{details.need_energy}
              </Button>
            ) : (
              <Button
                size={"lock"}>
                Game energy deficiency
              </Button>
            )}

          </div>
          {(gameHistory && gameHistory.length > 0) && (
            <ul className="history">
              <div className="title">
                Recent Players
              </div>
              {gameHistory.map((items, index) => {
                return (
                  <li key={`play_li_${index}_${items.id}`}>
                    <span><FaCreativeCommonsSampling />&nbsp;{items.summary_points}</span><span>&nbsp;{items.nickname}</span><span>{items.time_difference}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
      <Footer />
      <Loader isOpen={loaderState} />
      <Modal styleCss={'default'} isOpen={modalOpen} onClose={closeModal}>
        <div className='facebook-share-diglog'>
          <div className='share-title'>Share on Facebook to get more friends to play with you!</div>
          <div className='share-quote'>
            {shareParams.gameDesc}
          </div>
          <div className='share-button'>
            <FacebookShareButton
              quote={shareParams.gameDesc}
              imageUrl={shareParams.gameImg}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GameDetails;
