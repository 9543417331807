import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaRedoAlt, FaCreativeCommonsSampling, FaVolumeUp } from "react-icons/fa";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import ImageLoader from "../../components/ImageLoader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";

import "./index.css";

import { get, noTokenGet } from "../../utils/http";
import { useGlobalState } from "../../store/index";
import { error } from "../../utils/toast";

const Lottery = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [globalEnergy, setGlobalEnergy] = useGlobalState(
    "globalEnergy"
  );
  const [globalPoints, setGlobalPoints] = useGlobalState(
    "globalPoints"
  );
  const [isFlashing, setIsFlashing] = useState(false); // 控制闪烁状态
  const [flashingItem, setFlashingItem] = useState(null); // 当前闪烁的格子
  const [loaderState, setLoaderState] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [lotteryDetails, setLotteryDetails] = useState({});
  const [lotterysType, setLotterysType] = useState("details");
  const [lotterys, setLotterys] = useState([]);
  const [games, setGames] = useState([]);
  const [notice, setNotice] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await fetchMyLotteryNotice();
      await fetchMyLotteryPlan(0);
      setLoaderState(false);
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchMyLotteryNotice = async () => {
    const rep = await noTokenGet("g1Apps/getLotteryNotice", {});
    if (rep.code === 0) {
      setNotice(rep.data.lotteryRecords);
    } else {
      error(rep.msg);
    }
  };

  const fetchMyLotteryPlan = async (_refresh) => {
    const rep = await get("g1Apps/getMyLotteryPlan", {
      isRefresh: _refresh,
    });
    if (rep.code === 0) {
      setLotterys(rep.data.lotteryPlanData);
      setGames(rep.data.assetData);
      setGlobalEnergy(rep.data.assetData.energy);
      setGlobalPoints(rep.data.assetData.points);
    } else {
      error(rep.msg);
    }
  };

  const handleRefresh = () => {
    fetchMyLotteryPlan(1);
  };

  const handleShowLotterys = (_activeRows) => {
    console.log('handleShowLotterys=>_activeRows=>', _activeRows)
    setLotteryDetails(_activeRows);
    openModal('details');
  };

  const openModal = (_type) => {
    setLotterysType(_type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const fetchMakeLottery = async () => {
    const rep = await get("g1Apps/makeLottery", {});
    if (rep.code === 0) {
      setLotteryDetails(rep.data.lotteryPoolData);
      setGames(rep.data.assetData);
      startLottery(rep.data.assetData.energy, rep.data.assetData.points);
    } else {
      error(rep.msg);
    }
  };

  const handleStartLottery = async () => {
    await fetchMakeLottery();
  };

  // 开始抽奖的函数
  const startLottery = (_energy, _points) => {
    setIsFlashing(true);
    let flashCount = 10; // 闪烁次数

    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * 9); // 随机选择一个格子
      setFlashingItem(randomIndex);

      flashCount--;
      if (flashCount === 0) {
        clearInterval(interval); // 停止闪烁
        openModal('lotterys');
        setIsFlashing(false);
        setGlobalEnergy(_energy);
        setGlobalPoints(_points);
      }
    }, 300); // 每300ms闪烁一次
  };

  const handlePlayGame = async () => {
    navigate(`/gameList`);
  };

  return (
    <div className="app">
      <Seo
        title={`1Game - `}
        description={`1Game - `}
        keywords={`1Game,`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/1game.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} check={true} />
      <div className="lottery">
        <div className="announcement-container">
          <div className="announcement-list">
            {notice.map((announcement, index) => (
              <div className="announcement-item" key={`notice_${index}`}>
                <FaVolumeUp />&nbsp;{announcement.nickname}&nbsp;wins&nbsp;<ImageLoader src={announcement.lottery_cover} />&nbsp;x&nbsp;{announcement.award_amount}
              </div>
            ))}
            <div className="announcement-item">List of winners</div>
          </div>
        </div>
        <div className="wheel">
          {lotterys.map((item, index) => (
            <div
              key={`lotterys_item_${index}`}
              className={`item ${isFlashing && flashingItem === index ? 'an-flash' : ''}`}
              onClick={() => handleShowLotterys(item)}
            >
              <ImageLoader src={item.lottery_cover} />
            </div>
          ))}
        </div>
        {games.points >= 25 && (
          <div className="refresh" onClick={() => handleRefresh()}>
            <FaRedoAlt />&nbsp;Refresh&nbsp;(&nbsp;cost<span className="points"><FaCreativeCommonsSampling /></span>25&nbsp;)
          </div>
        )}
        <div className="operation">
          {games.points >= 50 ? (
            <Button
              size={"small-default"}
              onClick={() => handleStartLottery()}>
              Start Lottery&nbsp;/&nbsp;<FaCreativeCommonsSampling />&nbsp;50
            </Button>
          ) : (
            <Button
              size={"lock"}>
              Not enough points!Go play the game.
            </Button>
          )}
        </div>
        <div className="bubble" onClick={() => handlePlayGame()}>
          <div className="bubble-desc">
            Play Games To Get More Points <span className="icon-arrow-right"></span>
          </div>
          <div className="bubble-imgs">
            <img src="/images/play-game.png" alt="Play Game" />
          </div>
        </div>
      </div>
      <Footer />
      <Modal styleCss={"default"} isOpen={modalOpen} onClose={closeModal}>
        {lotteryDetails ? (
          <div className="lottery-details">
            {lotterysType === 'lotterys' && (<div className="rows">You Win</div>)}
            <ImageLoader
              css="pic"
              src={lotteryDetails.lottery_cover || ""}
              alt={lotteryDetails.lottery_name || ""}
            />
            <div className="rows">{lotteryDetails.lottery_name}&nbsp;x&nbsp;{lotteryDetails.award_amount}</div>
          </div>
        ) : null}
      </Modal>
      <Loader isOpen={loaderState} />
    </div >
  );
}

export default Lottery;
