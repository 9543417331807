import {
    createGlobalState
} from 'react-hooks-global-state';

export const {
    useGlobalState
} = createGlobalState({
    globalUserId: '',
    globalIsLogin: false,
    globalEnergy: 0,
    globalPoints: 0,
});