import React, { useState } from 'react';
import { useNavigate, useMatch } from "react-router-dom";

import "./index.css";

import config from "../../utils/global";

const Footer = ({ bgColor }) => {
    const navigate = useNavigate();
    const match = useMatch('*');
    const [menuFooterItems] = useState([
        { id: 1, title: 'Game list', link: '/gameList' },
        { id: 2, title: 'Lottery', link: '/lottery' },
        { id: 3, title: 'Friend\'s Help', link: '/friendsHelp' },
        { id: 4, title: 'Prize Claim', link: '/prizeClaim' },
        { id: 5, title: 'My 1Game Account', link: '/my1GameAccount' },
        { id: 6, title: 'Yvote', link: config.api.voteURL }
    ]);

    const handleUrl = (_id, _url) => {
        if (_id === 1) {
            window.location.href = _url;
            return;
        }
        navigate(_url);
    };

    return (
        <>
            <div className="footer" style={{ backgroundColor: bgColor ? bgColor : '#070605' }}>
                {menuFooterItems.map((item) => {
                    return (
                        <div key={`footer_${item.id}`} className={item.link === match.pathname ? 'li current' : 'li'} onClick={() => handleUrl(item.id, item.link)}>{item.title}</div>
                    )
                })}
                <div className="co-lien">
                    <p className='link'>Contact us</p>
                    <p className='smlink' style={{ marginTop: '0.24rem' }}>Tel: <a href="tel:02888839979">02888839979</a></p>
                    <p className='smlink'>Email: <a href="mailto:cskh@yvote.vn">cskh@yvote.vn</a></p>
                </div>
                <div className="co-icon">
                    <div className='co-icons'>
                        <a href="https://www.facebook.com/AnhTraiVuotNganChongGai" target="_blank">
                            <img className="ig-logo" src="https://yvote.vn/static/images/fb-icon.png" width="40" height="40" />
                        </a>
                        <a href="https://www.instagram.com/anhtraivuotnganchonggai.yeah1" target="_blank">
                            <img className="ig-logo" src="https://yvote.vn/static/images/ig-icon.png" width="40" height="40" />
                        </a>
                        <a href="https://www.youtube.com/@1PRO.ENTERTAINMENT" target="_blank">
                            <img className="ig-logo" src="https://yvote.vn/static/images/yt-icon.png" width="40" height="40" />
                        </a>
                        <a href="https://www.tiktok.com/@anhtraivuotnganchonggai" target="_blank">
                            <img className="ig-logo" src="https://yvote.vn/static/images/tiktok-icon.png" width="40" height="40" />
                        </a>
                    </div>
                    <div className='co-logo'>
                        <img alt="logo" src="https://yvote.vn/static/images/industry-trade-ministry-approved.png" width="126" height="47" />
                    </div>
                </div>
                <div className="co">
                    <p>Yvote là dịch vụ bình chọn được cung cấp bởi Công ty TNHH 1Production.</p>
                    <p>CÔNG TY TNHH 1PRODUCTION</p>
                    <p>Địa chỉ: 140 Nguyễn Văn Thủ, Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh, Việt Nam</p>
                    <p>Mã số doanh nghiệp: 0317690271</p><p>Cấp lần đầu ngày: 21/02/2023</p>
                    <p>Nơi cấp: Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh</p>
                    <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="Funfusion" />
                    &copy;2024 CÔNG TY TNHH 1PRODUCTION
                </div>
            </div>
            <footer className="pc-footer">
                <div className="footer-container">
                    <div className='footer-rows'>
                        <div className="footer-box">
                            <ul>
                                {menuFooterItems.map((item, index) => (
                                    (
                                        <li key={`footer_${item.id}`} onClick={() => handleUrl(item.id, item.link)}>{item.title}</li>
                                    )
                                ))}
                            </ul>
                        </div>
                        <div className="footer-box">
                            <p className='link'>Contact us</p>
                            <p className='smlink' style={{ marginTop: '20px' }}>Tel: <a href="tel:02888839979">02888839979</a></p>
                            <p className='smlink'>Email: <a href="mailto:cskh@yvote.vn">cskh@yvote.vn</a></p>
                        </div>
                        <div className="footer-box footer-box-large">
                            <p>Yvote là dịch vụ bình chọn được cung cấp bởi Công ty TNHH 1Production.</p>
                            <p>CÔNG TY TNHH 1PRODUCTION</p>
                            <p>Địa chỉ: 140 Nguyễn Văn Thủ, Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh, Việt Nam</p>
                            <p>Mã số doanh nghiệp: 0317690271</p><p>Cấp lần đầu ngày: 21/02/2023</p>
                            <p>Nơi cấp: Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh</p>
                        </div>
                    </div>
                    <div className='footer-rows'>
                        <div className="footer-icon">
                            <div className='icon-list'>
                                <a href="https://www.facebook.com/AnhTraiVuotNganChongGai" target="_blank">
                                    <img className="ig-logo" src="https://yvote.vn/static/images/fb-icon.png" width="40" height="40" />
                                </a>
                                <a href="https://www.instagram.com/anhtraivuotnganchonggai.yeah1" target="_blank">
                                    <img className="ig-logo" src="https://yvote.vn/static/images/ig-icon.png" width="40" height="40" />
                                </a>
                                <a href="https://www.youtube.com/@1PRO.ENTERTAINMENT" target="_blank">
                                    <img className="ig-logo" src="https://yvote.vn/static/images/yt-icon.png" width="40" height="40" />
                                </a>
                                <a href="https://www.tiktok.com/@anhtraivuotnganchonggai" target="_blank">
                                    <img className="ig-logo" src="https://yvote.vn/static/images/tiktok-icon.png" width="40" height="40" />
                                </a>
                            </div>
                            <div className='logo-list'>
                                <img alt="logo" src="https://yvote.vn/static/images/industry-trade-ministry-approved.png" width="126" height="47" />
                            </div>
                        </div>
                        <div className="footer-box footer-box-large">
                            <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="Funfusion" height="61" />
                            <p>&copy;2024 CÔNG TY TNHH 1PRODUCTION</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
